import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { parse } from 'query-string';

import Form from './myhsf/form';

import { getStage } from '../apis/public/stages';
import { FORM_DEBUG_QUERY_KEY } from '../utils/form';

type FormPreviewProps = RouteComponentProps & {
  stageId?: string;
};

// Public form preview page by share link
const FormPreview = ({ location, stageId, ...rest }: FormPreviewProps) => {
  const qs = location ? parse(location.search) : {};
  const isDebug = qs[FORM_DEBUG_QUERY_KEY] === 'true';
  return <Form {...rest} stageId={stageId} isPreview isDebug={isDebug} getStage={getStage} />;
};

export default FormPreview;
