import { Router } from '@reach/router';
import React from 'react';

import SEO from '../components/seo';
import PublicLayout from '../layout/public-layout';
import FormPreview from '../containers/form-preview';

export default function FormPreviewPage() {
  return (
    <PublicLayout>
      <SEO title="Form Preview" />
      <Router>
        <FormPreview path="/form-preview/:stageId" />
      </Router>
    </PublicLayout>
  );
}
