import axios from 'axios';

import responseParser from '../response-parser';
import { createError } from '../error';
import { API_URL } from '../../constants';
import { IStage } from '../../types/models/program-management';

export async function getStage(id: string): Promise<IStage> {
  const url = `${API_URL}/stages/${id}`;
  try {
    const response = await axios.get(url);
    const data: IStage = responseParser(response);
    return data;
  } catch (err) {
    throw createError(err);
  }
}
getStage.QUERY_KEY = 'getStage';
